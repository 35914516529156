<template>
    <Results
      :sourceAPI="finametricaSourceAPI"
      :riskToleranceView="riskToleranceView"
      :region="region"
      :lang="lang"
    />
</template>

<script>
import Results from './Results';
import resultMixin from '../mixins/results';
import { getURLParameter } from "../helper";

export default {
  name: "fmResults",
  mixins: [resultMixin],
  components: {
    Results
  },
  computed: {
    riskToleranceView() {
      return getURLParameter('riskToleranceView') === 'true';
    },
    region() {
      return getURLParameter('region');
    },
    lang() {
      return getURLParameter('lang');
    }
  }
};
</script>
<style lang="scss">
.mds-layout-grid__row___mwc-fm:nth-child(1) .mds-layout-grid__col___mwc-fm:nth-child(2) {
    display: none;
}

.next-button {
  float: right;
}

.results-wrapper {
  text-align: left;
  padding-left: 5rem;
}
</style>